import {request, api} from '../utils/index';

const {
    // client_auth_get,
    auth_token,
    auth_ocr,
    auth_submit,
    face_token,
    face_identify,
    oss_sts
} = api;

// export function Client_auth_get(data) {
//   return request({
//     url: client_auth_get,
//     method: 'post',
//     data,
//   })
// }

export function Auth_token(data) {
    return request({
      url: auth_token,
      method: 'post',
      data,
    })
}
export function Oss_sts(data) {
  return request({
    url: oss_sts,
    method: 'post',
    data,
  })
}
  export function Auth_ocr(data) {
    return request({
      url: auth_ocr,
      method: 'post',
      data,
    })
  }
  export function Auth_submit(data) {
    return request({
      url: auth_submit,
      method: 'post',
      data,
    })
  }
  export function Face_token(data) {
    return request({
      url: face_token,
      method: 'post',
      data,
    })
  }
  export function Face_identify(data) {
    return request({
      url: face_identify,
      method: 'post',
      data,
    })
  }