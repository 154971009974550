import {
    Loan,
    Loan_fee,
    Repay_Info_Stage,
    Repay_Info_All,
    Bank_list,
    Valid_card,
    Bind_Capital_Card_sms,
    Bind_Card,
    Bind_Card_List,
    Repay_Send_Sms,
    Repay_Submit,
    Pay_Check_Settle,
    Pay_Check_Normal,
    Loan_Contract,
} from '../services/loan';
import { Client_auth_get } from "../services";


export default {
    namespace: 'NSLoan',
    state: {
        name: 'NSLoan',
        ClientInfo: {},
        LoanFee: null,
        BankListInfo: [],
        RepayList: null,
        LoanInfo: null,
        PayInfo: null,
        ValidCardStatus: null,
    },
    reducers: {
        redu_client_info(state, { payload }) {
            return {
                ...state,
                ClientInfo: payload
            }
        },
        redu_loan_fee(state, { payload }) {
            return {
                ...state,
                LoanFee: { ...payload }
            }
        },
        redu_bank_list(state, { payload }) {
            return {
                ...state,
                BankListInfo: payload
            }
        },
        redu_bank_qinjia_list(state, { payload }) {
            return {
                ...state,
                BankListInfo: payload
            }
        },
        redu_valid_card(state, { payload }) {
            return {
                ...state,
                ValidCardStatus: payload
            }
        },
        redu_stage_info(state, { payload }) {
            return {
                ...state,
                RepayList: payload.planRemindList,
                LoanInfo: payload.loanVO
            }
        },
        redu_repay_info(state, { payload }) {
            return {
                ...state,
                PayInfo: payload
            }
        }
    },
    effects: {

        /* 獲取用戶授信額度 1*/
        * eff_client_info({ payload, callback }, { put, call, select }) {
            const token = yield select(state => state.token);
            if (token !== null) {
                const res = yield call(Client_auth_get, { ...payload });
                if (res.succ) {
                    const {middle}=JSON.parse(res.desData)
                    yield put({ type: 'redu_client_info', payload:middle});
                    callback && callback(middle);
                }
            }
        },
        /*提交借款1*/
        * loan_create({ payload, callback }, { put, call }) {
            
            const res = yield call(Loan, { ...payload });
            if (res.succ) {
                // const {middle}=JSON.parse(res.desData)
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
           /*借款计算服务费 1*/
        * loan_fee({ payload, callback }, { put, call }) {
         
            const res = yield call(Loan_fee, { ...payload });
            if (res.succ) {
                const {middle}=JSON.parse(res.desData)
                // console.log(res,'loanfee')
                if (callback && typeof callback === 'function') {
                    callback(middle);
                }
                yield put({ type: 'redu_loan_fee', payload: middle });
            }
        },
        // 获取银行卡列表 1
        * eff_bank_list({ payload, callback }, { put, call }) {
            const res = yield call(Bank_list, { ...payload });
            if (res.succ) {
                const {middle}=JSON.parse(res.desData)
                yield put({ type: 'redu_bank_list', payload:middle});
                if (callback && typeof callback === 'function') {
                    callback(middle);
                }
            }
        },
        //判断是否需要签约其他渠道 1
        * eff_valid_card({ payload, callback }, { put, call }) {
            const res = yield call(Valid_card, { ...payload });
            if (res.succ) {
                // console.log(res,'qianyue')
                // const {desData}=JSON.parse(res.desData) 
                yield put({ type: 'redu_valid_card', payload: res });
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
         /*绑定亲家签约 1*/
        * bind_capital_card_sms({ payload, callback }, { put, call }) {
           
            const res = yield call(Bind_Capital_Card_sms, { ...payload });
            if (res.succ) {
                console.log(res,'yanzhengma2')
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        /*绑定签约银行卡 1*/
        * bind_card({ payload, callback }, { put, call }) {
            
            const res = yield call(Bind_Card, { ...payload });
            if (res.succ) {
                console.log(res,'bindbank')
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },

        * bind_card_list({ payload, callback }, { put, call }) {
            /*签约银行卡列表*/
            const res = yield call(Bind_Card_List, { ...payload });
            if (res.succ) {
                yield put({ type: 'redu_bank_qinjia_list', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res.result);
                }
            }

        },
          /*还款详情 1*/
        * repay_stage_info({ payload, callback }, { put, call }) {
          
            const res = yield call(Repay_Info_Stage, { ...payload });
            if (res.succ) {
                // console.log(res,'pay')
                const {middle}=JSON.parse(res.desData)
                yield put({ type: 'redu_stage_info', payload:middle });
                if (callback && typeof callback === 'function') {
                    callback(middle);
                }
            }
        },
         /*全部结清 1*/
        * repay_all_info({ payload, callback }, { put, call }) {
           
            const res = yield call(Repay_Info_All, { ...payload });
            if (res.succ) {
                // const {middle,status}=JSON.parse(res.desData)
                // yield put({ type: 'redu_repay_info', payload: res.result });
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
         /*还款发送短信 1*/
        * repay_send_sms({ payload, callback }, { put, call }) {
           
            const res = yield call(Repay_Send_Sms, { ...payload });
            if (res.succ) {
                // console.log(res,'duanxin')
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
        /*还款成功 1*/
        * repay_submit({ payload, callback }, { put, call }) {
         
            const res = yield call(Repay_Submit, { ...payload });
            if (res.succ) {
                // console.log(res,'submit')
                
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
           /*全部还款检查 1*/
        * check_settle({ payload, callback }, { put, call }) {
           
            const res = yield call(Pay_Check_Settle, { ...payload });
            if (res.succ) {
                // console.log(res,'payall')
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
         /*单期还款检查 1*/
        * check_normal({ payload, callback }, { put, call }) {
           
            const res = yield call(Pay_Check_Normal, { ...payload });
            if (res.succ) {
                // console.log(res,99999)
                if (callback && typeof callback === 'function') {
                    callback(res);
                }
            }
        },
         /*获取协议列表 1*/
        * loan_contract_info({ payload, callback }, { put, call }) {
           
            const res = yield call(Loan_Contract, { ...payload });
            if (res.succ) {
                // const {middle}=JSON.parse(res.desData)
                callback && callback(res);
            }
        }
    }
};
