

const routes = [
  {
    id: 'auth',
    name: "认证列表",
    desc: '认证列表',
    path: '/auth',
    component: () => import('../routes/auth')
  },
  {
    id: 'authIdentity',
    name: "实名认证",
    desc: '实名认证',
    path: '/authIdentity',
    component: () => import('../routes/authIdentity')
  },
  {
    id: 'authFace',
    name: "人脸识别",
    desc: '人脸识别',
    path: '/authFace',
    component: () => import('../routes/authFace')
  },
  {
    id: 'authBaseInfo',
    name: "基础信息",
    desc: "基础信息",
    path: '/baseInfo',
    component: () => import('../routes/baseInfo')
  },
  {
    id: 'bankAdd',
    name: "添加银行卡",
    desc: '添加银行卡',
    path: '/bankAdd',
    component: () => import('../routes/bankAdd')
  },
  {
    id: 'bankResult',
    name: '查看银行',
    path: '/bankResult',
    component: () => import('../routes/bankResult')
  },
  {
    id: 'bindBank',
    name: "绑卡",
    desc: '绑卡',
    path: '/bindBank',
    component: () => import('../routes/bindBank')
  },
  {
    id: 'recommend',
    name: "推荐分期",
    desc: "推荐分期",
    path: '/recommend',
    component: () => import('../routes/recommend')
  },
  {
    id: 'bill',
    name: "账单",
    desc: "账单",
    path: '/bill',
    component: () => import('../routes/bill')
  },
  {
    id: 'applyResult',
    name: "申请结果",
    desc: '申请结果',
    path: '/applyResult',
    component: () => import('../routes/applyResult')
  },

  /* Front*/
  {
    id: 'loan',
    name: "借款",
    desc: '借款页面',
    path: '/loan',
    component: () => import('../routes/loan')
  },
  {
    id: 'loanResult',
    name: "借款结果",
    desc: '借款结果页面',
    path: '/loanResult',
    component: () => import('../routes/loanResult')
  },
  {
    id: 'pay',
    name: "账单详情",
    desc: "还款首页",
    path: '/pay',
    component: () => import('../routes/pay')
  },
  {
    id: 'payAll',
    name: "信息确认",
    desc: "全部还款",
    path: '/payAll',
    component: () => import('../routes/payAll')
  },
  {
    id: 'payStage',
    name: "确认还款金额",
    desc: "单期还款",
    path: '/payStage',
    component: () => import('../routes/payStage')
  },
  {
    id: 'payConfirm',
    name: "支付",
    desc: '还款确认页',
    path: '/payConfirm',
    component: () => import('../routes/payConfirm')
  },
  {
    id: 'payResult',
    name: "还款",
    desc: '还款结果页',
    path: '/payResult',
    component: () => import('../routes/payResult')
  },
  {
    id: 'loanContract',
    name: "接口合同",
    desc: '接口合同',
    path: '/loanContract',
    component: () => import('../routes/loanContract')
  },
  {
    id: 'demo',
    name: "API接口列表",
    desc: 'API接口',
    path: '/demo',
    component: () => import('../routes/demo')
  },
  {
    id: 'feedBack',
    name: "投诉及反馈",
    desc: '投诉及反馈',
    path: '/feedBack',
    component: () => import('../routes/feedBack')
  },
  {
    id: 'protocolList',
    name: "查看协议",
    desc: '查看协议',
    path: '/protocolList',
    component: () => import('../routes/protocolList')
  },
];


export default routes;
