import dva from 'dva';
import 'normalize.css'
import './assets/layout.less';
// import {persistEnhancer} from 'dva-model-persist';
import createHashHistory from 'history/createHashHistory'
import createLoading from 'dva-loading';
import * as serviceWorker from './serviceWorker';
import {Toast} from 'antd-mobile';
import models from './models/models';
import APP_API from './utils/APP';
import initReactFastclick from 'react-fastclick';

// 初始化fastClick
initReactFastclick();

window.APP = APP_API;

// 1. Initialize
const appDva = dva({
  history: createHashHistory(),
  onError: (e) => {
    e.preventDefault();

    const {message} = e;
    Toast.show(message, 1.5,false);
    // 错误统一处理
  }
});

// 2. Plugins
appDva.use(createLoading());

// 本地缓存
// appDva.use({
//   extraEnhancers: [
//     persistEnhancer({
//       key: "root",
//       keyPrefix:"",
//     })
//   ],
// });

models.forEach((item) => {
  appDva.model(item)
});
// 3. Model
// app.model(require('./models/app').default);

// 4. Router
appDva.router(require('./router').default);

// 5. Start
appDva.start("#root");
// document.title = "借款";
serviceWorker.unregister();
