import axios from 'axios'
import qs from 'qs';
import APP from './APP';
import {BaseUrl} from "./index";
import {desCode} from "./encryption";
import {Toast} from "antd-mobile";
import app from '../models/app';

const {Env} = require('../config/env');

//eslint-disable-next-line no-unused-vars
const isEncryption = Env === 'prod';
// 添加请求拦截器，提交的时候加密
axios.interceptors.request.use( async(config) =>{
    //    1-1
    try{
        const tokenUser = await APP.GET_Token();
        if(tokenUser){
            config.headers.authorization = tokenUser
        }
        // console.log(tokenUser,888)
    }catch(err){
        console.log(err.msg)
    }
    // 1 、本地
    // config.headers.authorization='eyJhbGciOiJIUzI1NiJ9.eyJhdXRoVG9rZW4iOiI1YTdhNGI1N2ZlNDY0YTRkOTgzY2Y0MDA4OTdkMGY3ZSIsImlzcyI6IkFQUC1QT1JUQUwiLCJzZXNzaW9uSWQiOiJjOTg3ZmY2YTY2ZmViNTVlZTEyMTc5ODRhMDBiNjlkYSIsImV4cCI6MTY2NDkzNjQzOCwiaWF0IjoxNjU3MTYwNDM4LCJqdGkiOiJkZmIwODk0Mi0zMjZmLTQzNmUtYTJkMC1lNGFhZjFlZjIxNTQifQ.qyjGzHd1gKsIUHGs5ODtg3-qSzQVWptaDxtTfz5DsrQ'
    
    return config;
},function (error) {
        return Promise.reject(error);
});

// 添加返回拦截，返回的时候解密
axios.interceptors.response.use(function (response) {
    
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

/*Portal*/
const fetchFn = async (options) => {
    const {data, url} = options;
    // console.log(options,'请求开始')
    const clientNo = window.localStorage.getItem('clientNo');
    const productName = window.localStorage.getItem('productName');
    const loanNo = window.localStorage.getItem('loanNo');
    // 获取设备信息2-1
    let rd = window.localStorage.getItem('rd') 
        rd=JSON.parse(rd)
    if(!rd){
        rd= await APP.DEVICE();
        // console.log(rd,'rd') 
    }

    // 2、本地调试
//     let rd = {
//         "osVersion" : "15.2",
//         "clientIp" : "192.168.0.75",
//         "mobileType" : "iPhone13,1",
//         "availableStorage" : "34313.02 MB",
//         "clientCity" : "\U676d\U5dde\U5e02",
//         "clientProvince" : "\U6d59\U6c5f\U7701",
//         "deviceId" : "1",
//         "idfa" : "00000000-0000-0000-0000-000000000000",
//         "appSourceId" : "50",
//         "deviceName" : "Mini",
//         "wifiMac" : "",
//         "carrierOperator" : "\U65e0\U8fd0\U8425\U5546",
//         "appVersion" : "4.0.0",
//         "clientRegion" : "\U840d\U6c34\U897f\U885780\U53f7\U4f18\U76d8\U65f6\U4ee3\U4e2d\U5fc31\U53f7\U697c9\U697c, \U676d\U5dde\U5e02, \U897f\U6e56\U533a, \U6d59\U6c5f\U7701, \U4e2d\U56fd",
//         "pushId" : "",
//         "lng" : "120.095354",
//         "screenHeight" : "812",
//         "resolution" : "1125*2436",
//         "mac" : "02:00:00:00:00:00",
//         "electricQuantity" : "100.00%",
//         "appName" : "MMQB",
//         "token" : "5d37a4a4a44edd4a146af83f82f849fee36bc04c",
//         "networkStatus" : "Wifi",
//         "screenWidth" : "375",
//         "lat" : "30.302961",
//         "tId" : "",
//         "device" : "ios",
//         "totalStorage" : "60912.11 MB"
//     }
//    rd = JSON.stringify(rd);
//     window.localStorage.setItem('appName','MMQB')
    //到这个位置

    const clientParams={}
    if(clientNo){
        clientParams.clientNo = clientNo;
    }
    if(productName){
        clientParams.productName = productName;
    }
    if(loanNo){
        clientParams.loanNo = loanNo;
    }
    console.log(clientParams,loanNo,'传入参数1')
    if (clientParams || data.params) {
        rd = JSON.stringify(Object.assign({},clientParams,data.params, JSON.parse(rd)));
        // console.log(rd,'传入参数2')
        delete data.params;
        
    }

    // if (data.params) {
    //     rd = JSON.stringify(Object.assign({},data.params, JSON.parse(rd)));
    //     delete data.params;
       
    // }


    if (Env === "prod") {
        data.rd = desCode.ncrypted(rd);
    } else {
        data.rd = rd;
        // console.log(3)
    }
    
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    const fetchAxios = axios.post(url, data, {
        
        transformRequest: [function (data) {
            data = qs.stringify(data);
            return data;
        }],
        withCredentials: true,
        headers,
    });
    // console.log(fetchAxios,'qingqiu')

    return fetchAxios;
};

export default async function request(options) {

    return fetchFn(options)
        .then((response) => {
           
         
            const {config} = response;
            const {url} = config;
            let data = response.data;
            if (data.code === '500') {
                throw new Error(data.message);
            }
            if (url.indexOf(BaseUrl.Portal) !== -1) {
                const {desData} = data;
                if (!data.succ && data.code==500 ) {
                    console.log(data.succ,data,'xiangying2')
                    throw new Error(data.message);
                }
                // 如果是生成环境，就解密
                if (Env === "prod") {
                    data.desData = desCode.decrypted(desData);
                }

                return Promise.resolve({...data})

            } else {
                // console.log(data,'xiangying3')
                return Promise.resolve({...data})
            }


            //Toast.hide();
        })
        .catch((error) => {
            Toast.hide();
            const {response} = error;
            let msg;
            let statusCode;
            if (response && response instanceof Object) {
                const {data, statusText} = response;
                statusCode = response.status;
                msg = data.message || statusText;
            } else {
                statusCode = 600;
                msg = error.message || '服务器开小差了...';
                if (error.message === "Network Error") {
                    msg = "服务器开小差了"
                }
            }

            /* eslint-disable */
            return Promise.reject({succ: false, statusCode, message: msg})
        })
}
