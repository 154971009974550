// const API = 'http://47.99.245.36:8096';
// const API = 'https://front.jqtxxedk.com';
// const API = 'https://middle.jqtianxia.com';

// const API = '/dev';
const {API_Portal,API_Front} = require('./env');
// const API = 'http://121.196.26.20:8096';

export default {
  /*Portal 所有接口*/
  interface_url: `${API_Portal}/app/apiGateway.do`,

  /*授权接口*/
  client_auth_get: `${API_Portal}/app/apiGateway.do`,
  /* 实名认证获取token */
  auth_token: `${API_Portal}/app/apiGateway.do`,
  /* 实名认证上传 sts */
  oss_sts: `${API_Portal}/app/apiGateway.do`,
  /* 身份证OCR */
  auth_ocr: `${API_Portal}/app/apiGateway.do`,
  /* 实名认证提交 */
  auth_submit: `${API_Portal}/app/apiGateway.do`,
  /* 人脸识别获取token */
  face_token: `${API_Portal}/app/apiGateway.do`,
  /* 人脸识别 */
  face_identify: `${API_Portal}/app/apiGateway.do`,

  
  /*借款提交*/
  loan_submit: `${API_Portal}/app/apiGateway.do`,
  /*借款费用计算*/
  loan_fee: `${API_Portal}/app/apiGateway.do`,
  /*借款协议*/
  loan_contract:`${API_Portal}/app/apiGateway.do`,


  /*分期数据*/
  init_pay_stage: `${API_Portal}/app/apiGateway.do`,
  /*全部结清数据*/
  init_pay_all: `${API_Portal}/app/apiGateway.do`,
  /*还款短信*/
  init_pay_sms:`${API_Portal}/app/apiGateway.do`,
  /*还款*/
  pay_submit:`${API_Portal}/app/apiGateway.do`,
  /*亲家结清、还款检查*/
  pay_check_normal:`${API_Portal}/app/apiGateway.do`,
  pay_check_settle:`${API_Portal}/app/apiGateway.do`,


  /*用户银行卡列表*/
  init_Bank_list: `${API_Portal}/app/apiGateway.do`,
  /* 用户是否签约过亲家渠道 */ 
  init_Valid_card: `${API_Portal}/app/apiGateway.do`,
  /* 签约短信和银行卡签约 */   
  init_Card_sms: `${API_Portal}/app/apiGateway.do`,
  init_Card_bind: `${API_Portal}/app/apiGateway.do`,
  /* 用户已经签约的银行卡 */  
  init_Card_bind_list: `${API_Portal}/app/apiGateway.do`,

}


