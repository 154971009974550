import APP from '../utils/APP';
import {SearchParams} from "../utils";
import {request, api} from '../utils/index';
import { Toast } from 'antd-mobile';
const Interface_url = function (data) {
    return request({
        url: api.interface_url,
        method: 'post',
        data,
    })
};

const {BROWSER} = APP;
const GetToken = () => new Promise(resolve => {
    // 带#号时获取不到参数的问题
    const search = SearchParams(window.location.hash.split("?")[1]);

    const clientNo = search.get('clientNo');
    const productName = search.get('productName');
    const loanNo = search.get('loanNo');
    const capitalCode = search.get('capitalCode');
    console.log(capitalCode,loanNo,888);
    window.localStorage.setItem('clientNo', clientNo);
    window.localStorage.setItem('productName', productName);
    window.localStorage.setItem('loanNo', loanNo);
    window.localStorage.setItem('capitalCode', capitalCode);
    resolve([clientNo,productName,loanNo]);
});

const GetLoginFlag = () => new Promise(resolve => {
    APP.IS_LOGIN(function (flag) {
        const isLogin = flag !== 'false';
        resolve(isLogin);
    });
});


export default {
    namespace: 'NSApp',
    state: {
        index: {},
        authList: {},
        bill: null,
        baseInfo: {},
        clientNo: null,
        feedBackType: null,
        isLogin: false
    },
    reducers: {
        updateLoginFlag: function (state, {payload}) {
            return {
                state,
                isLogin: payload
            }
        },
        updateFeedBackType: function (state, {payload}) {
            return {
                state,
                feedBackType: payload.type
            }
        }, updateIndex: function (state, {payload}) {
            return {
                state,
                index: payload
            }
        },
        updateAuthList: function (state, {payload}) {
            return {
                state,
                authList: payload
            }
        },
        updateBill: function (state, {payload}) {
            return {
                state,
                bill: payload
            }
        },
        updateBase: function (state, {payload}) {
            return {
                state,
                baseInfo: payload
            }
        },

        /*Front*/
        redu_update_clientNo(state, {payload}) {
            return {
                ...state,
                clientNo: payload
            }
        },
    },
    effects: {
        * eff_request({payload, callback}, {put, call}) {
            const res = yield call(Interface_url, {...payload});
            if (res.succ) {
                // console.log(res,888)
                const {at} = payload;
                const result = JSON.parse(res.desData);
                // console.log(result,855)
                switch (at) {
                    case "user.advise.init":
                        // 认证列表
                        yield put({type: "updateFeedBackType", payload: result});
                        break;
                    case "thirddata.auth.list":
                        // 认证列表
                        yield put({type: "updateAuthList", payload: result});
                        break;
                    case "yyyq.app.index":
                        yield put({type: "updateIndex", payload: result});
                        break;
                    case "bills.unreturned.list":
                        yield put({type: "updateBill", payload: result});
                        break;
                    case "client.simpleinfo.init"://个人基础信息初始化
                        yield put({type: "updateBase", payload: result});
                        break;
                       
                    //新增middle
                    // case "middle.get.card.list"://获取用户银行卡
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.card.sms"://签约发送验证码
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.card.binding"://银行卡签约
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.is.valid.card"://是否需要重复签约其他渠道
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.client.card"://获取用户放款银行卡
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.user.credit.info"://获取用户授信信息
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.before.agreements"://获取借款前协议
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.loan.config"://获取借款配置信息
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.fee"://借款费用计算
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.before.agreements"://获取借款钱协议
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.create"://提交借款
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.plan.list.by.client.no"://客户还款获取计划列表(按期)
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.get.pre.loan.plan.client.no":// 客户还款获取计划列表(全部结清)
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.card.band.sms"://客户发送还款短信
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    // case "middle.backed.apply":// 账单还款
                    //     yield put({type: "updateBase", payload: result});
                    //     break;
                    default:
                        console.log('其他接口');
                        break;
                }
                callback && callback(result);
            }else{
                if (callback && typeof callback === 'function') {
                    console.log(res,'bank error')
                    const result = JSON.parse(res.desData);
                    callback(result);
                }
                console.log(res.message,'2')
                Toast.info(res.message) 
            }
        },

        /*从Url当中获取token 异步封装*/
        * eff_init_token({payload, callback}, {put, call}) {
            const res = yield call(GetToken, {...payload});
            if (res) {
                yield put({type: 'redu_update_clientNo', payload: res});
                callback && callback(res);
            }
        },

        /*dispatch clientNo 当用户首页能够拿到ClientNo的时候，就保存到本地*/
        * eff_save_clientNo({payload, callback}, {put}) {
            const {clientNo} = payload;
            yield put({type: 'redu_update_clientNo', payload: clientNo});
            callback && callback(clientNo);
        },
    },
    subscriptions: {
        setup({dispatch, history}) {

            // 更新登录状态
            // dispatch({type: 'eff_init_isLogin', payload: {}});

            // 路由监听
            history.listen((location) => {
                const pathname = location.pathname;
                switch (pathname) {
                    case "/auth":
                        document.title = "信息认证";
                        BROWSER.isclient ? APP.SET_TITLE('信息认证') : document.title = '信息认证';
                        break;
                    case "/baseInfo":
                        document.title = "基础信息";
                        BROWSER.isclient ? APP.SET_TITLE('基础信息') : document.title = '基础信息';
                        break;
                    case "/bill":
                        document.title = "账单";
                        BROWSER.isclient ? APP.SET_TITLE('账单') : document.title = '账单';
                        break;
                    case "/applyResult":
                        document.title = "申请成功";
                        BROWSER.isclient ? APP.SET_TITLE('申请成功') : document.title = '申请成功';
                        break;

                    /*Front*/
                    case "/loan":
                        document.title = "借款";
                        BROWSER.isclient ? APP.SET_TITLE('借款') : document.title = '借款';
                        break;
                    case "/pay":
                        document.title = "账单详情";
                        BROWSER.isclient ? APP.SET_TITLE('账单详情') : document.title = '账单详情';
                        break;
                    case "/payAll":
                        //window.TDAPP.onEvent("全部结清页面进入");
                        BROWSER.isclient ? APP.SET_TITLE('信息确认') : document.title = '信息确认';
                        break;
                    case "/payStage":
                        BROWSER.isclient ? APP.SET_TITLE('确认还款金额') : document.title = '确认还款金额';
                        break;
                    case "/payConfirm":
                        BROWSER.isclient ? APP.SET_TITLE('支付') : document.title = '支付';
                        //window.TDAPP.onEvent("确认付款页面进入");
                        break;
                    case "/payResult":
                        BROWSER.isclient ? APP.SET_TITLE('支付') : document.title = '支付';
                        break;
                    case "/loanResult":
                        BROWSER.isclient ? APP.SET_TITLE('申请完成') : document.title = '申请完成';
                        break;
                    case "/loanContract":
                        BROWSER.isclient ? APP.SET_TITLE('查看协议') : document.title = '查看协议';
                        break;
                    case "/feedBack":
                        BROWSER.isclient ? APP.SET_TITLE('投诉及反馈') : document.title = '投诉及反馈';
                        break;
                    case "/protocolList":
                        BROWSER.isclient ? APP.SET_TITLE('查看协议') : document.title = '查看协议';
                        break;
                    case "/demo":
                        BROWSER.isclient ? APP.SET_TITLE('接口测试') : document.title = '接口测试';
                        break;
                    case "/recommend":

                        break;
                    default:
                        document.title = "首页";
                        BROWSER.isclient ? APP.SET_TITLE('首页') : document.title = '首页';
                        break;
                }
                // 显示title bar
                if (APP.BROWSER.ios && pathname !== '/') {
                    APP.SHOW_TITLE_BAR();
                } else {
                    APP.HIDE_TITLE_BAR();
                }
            })
        }
    }
};
