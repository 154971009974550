import React from "react";
import { connect } from "dva";
import "./index.less";
import { money, H5Url } from "../../utils";
import APP from "../../utils/APP";
import Banner from "./components/Banner";
import Recommend from "./components/Recommend";
import Header from "./components/Header";
import MainCard from "./components/MainCardBak";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numbers: [],
      animate: false,
    
    };
    this.Dt = this.Dt.bind(this); //解决this.setState({})在dt中不是一个函数的问题
  }

  componentDidMount() {
    const self = this;
    // 切换到前台展示的时候
    APP.PAGE_WILL_LOAD(function() {
      self.init();
    });
    window.TDAPP.onEvent("首页-进入");
    APP.SET_REFESH();
    // 第一次进入
    self.init();
    //公告栏
    if (self.state.numbers != undefined) {
      setInterval(self.Dt, 3000);
    }
  }

  init() {
    const { dispatch } = this.props;
    /*借款详情*/
    dispatch({
      type: "NSApp/eff_request",
      payload: {
        at: "yyyq.app.index",
        v: "1.0.0",
      },
      callback: (res) => {
        console.log(res, 86);
        this.setState({
          numbers: res.cash_list,
        });
      },
    });
  }
  //公告栏
  Dt() {
    const self = this;
    self.setState({
      animate: true,
    });
    if (this.state.numbers != undefined) {
      setTimeout(() => {
        self.state.numbers.push(self.state.numbers[0]);
        self.state.numbers.shift();
        self.setState({ animate: false });
        self.forceUpdate();
      }, 1000);
    }
  }

  render() {
    const { index } = this.props.NSApp;
    const { numbers, animate } = this.state;
    const {
      bannerList,
      top,
      times,
      cardIndex,
      clientNo,
      is_login,
      show_other_card,
    } = index;
    //去还款
    const RecordItem =
      show_other_card &&
      show_other_card.length == 1 &&
      show_other_card.map((item, index) => {
        return (
          <div
            className={"awj-reimbursement"}
            key={index}
            onClick={() => {
              APP.JUMP(item.url);
            }}
          >
            <div className={"awj-reimbursement-l"}>
              <div className={"l-time"}>
                <span>{item.loanDate}</span> <span>应还款(元)</span>
                {/* <span>06月30日</span> <span>应还款(元)</span> */}
              </div>
              <div className={"l-number"}>{item.loanAmount}</div>
              {/* <div className={"l-number"}>10000.00</div> */}
            </div>
            <div className={"awj-reimbursement-r"}>
              <span className={"r-text"}>去还款</span>
              <i className={"r-icon"} />
            </div>
          </div>
        );
      });
    const billRender =
      show_other_card &&
      show_other_card.length == 2 &&
      show_other_card.map((item, index) => {
        return (
          <div
            className={"bill-box"}
            key={index}
            onClick={() => {
              APP.JUMP(item.url);
            }}
          >
            <div className={"bill-b-top"}>
              <span>{item.loanDate}</span>
              <span>应还款(元)</span>
            </div>
            <div className={"bill-b-number"}>{item.loanAmount}</div>
            <div className={"bill-b-bottom"}>
              <span>去还款</span>
              <i />
            </div>
          </div>
        );
      });

    return (
      <div className={"index-mmqb"}>
        <div className={"mmqb-top"} />
      
        <div className={"mmqb-main"}>
              {/*状态卡片*/}
            <div className={"mmqb-card"}>
            <MainCard cardIndex={cardIndex} clientNo={clientNo} is_login={is_login}/>
            
            </div>
             {/* 通知 */}
            <div className={"card-notice"}>
                {numbers != undefined && (
                <div className={"awj-record"}>
                    <div className={"record-img"} />
                    <div className="cosultation-wrap">
                    <div className="cosulation-news">
                        <ul className={this.state.animate ? "anim" : "animNull"}>
                        {this.state.numbers.map((item, index) => (
                            <li className="consulation-news-item" key={index}>
                            {item}
                            </li>
                        ))}
                        </ul>
                    </div>
                    </div>
                </div>
                )}
            </div>
        </div>
        {/* 去还款;*/}
        <div className={"awj-bills"}>
          {show_other_card && show_other_card.length > 1 ? billRender: RecordItem}
        </div>
        {/*Banner广告图片*/}
        {bannerList && <Banner data={bannerList} times={times} />}
        <div
          className={"mmqb-footer"}
          onClick={() => {
            //跳转到省一下落地页
            APP.JUMP(
              "https://land.cloudsmake.com/landPageSj.html?td_channelid=867c70bbf7384ef3b5edf34d41187c8b&ssource=867c70bbf7384ef3b5edf34d41187c8b"
            );
          }}
        >
          <span>更多热门产品推荐</span>
          <span />
        </div>
      </div>
    );
  }
}

export default connect(({ loading, NSApp }) => ({ loading, NSApp }))(Index);
