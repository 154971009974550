import {request, api} from '../utils/index';

const {client_auth_get} = api;

export function Client_auth_get(data) {
  return request({
    url: client_auth_get,
    method: 'post',
    data,
  })
}


