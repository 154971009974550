import { Toast } from 'antd-mobile';
import {
    Auth_token,
    Auth_ocr,
    Auth_submit,
    Face_token,
    Face_identify,
    Oss_sts
} from '../services/auth';

export default {
    namespace: 'NSAuth',
    state: {
        name: 'NSAuth',
        // ClientInfo: {},
        // LoanFee: null,
        // BankListInfo: [],
        // RepayList: null,
        // LoanInfo: null,
        // PayInfo: null,
        // ValidCardStatus: null,
    },
    reducers: {
        // redu_client_info(state, { payload }) {
        //     return {
        //         ...state,
        //         ClientInfo: payload
        //     }
        // },
    },
    effects: {
        //获取实名token
        * auth_token_init({ payload, callback }, { put, call }) {
            const res = yield call(Auth_token, { ...payload });
            if (res.succ) {
                if (callback && typeof callback === 'function') {
                    const result = JSON.parse(res.desData);
                    callback(result);
                }
            }
        },
        //获取oss_sts
        * oss_sts_bucket({ payload, callback }, { put, call }) {
          const res = yield call(Oss_sts, { ...payload });
          if (res.succ) {

              if (callback && typeof callback === 'function') {
                  const result = JSON.parse(res.desData);
                  console.log(result,'sts')
                  callback(result);
              }
          }
        },
        //身份证OCR
        * auth_ocr({ payload, callback }, { put, call }) {
            const res = yield call(Auth_ocr, { ...payload });
            if (res.succ) {
                // yield put({ type: 'redu_valid_card', payload: res });
                if (callback && typeof callback === 'function') {
                    const result = JSON.parse(res.desData);
                    callback(result);
                }
            }else{
                console.log(res.message,'2')
                Toast.info(res.message)
            }
        },
        //实名提交
        * auth_identity_sub({ payload, callback }, { put, call }) {
            const res = yield call(Auth_submit, { ...payload });
            console.log(res,'authsub')
            if (res.succ) {
                // yield put({ type: 'redu_valid_card', payload: res });
                if (callback && typeof callback === 'function') {
                    const result = JSON.parse(res.desData);
                    
                    callback(result);
                }
            }else{
                console.log(res.message,'2')
                Toast.info(res.message)
            }
        },
        //人脸识别token
        * face_token_init({ payload, callback }, { put, call }) {
            const res = yield call(Face_token, { ...payload });
            if (res.succ) {
                // yield put({ type: 'redu_valid_card', payload: res });
                if (callback && typeof callback === 'function') {
                    
                    const result = JSON.parse(res.desData);
                    callback(result);
                }
            }else{
                console.log(res.message,'2')
                Toast.info(res.message)
            }
        },
        //人脸识别提交
        * face_identify_sub({ payload, callback }, { put, call }) {
            const res = yield call(Face_identify, { ...payload });
            if (res.succ) {
                // console.log(res,'face')
                // yield put({ type: 'redu_valid_card', payload: res });
                if (callback && typeof callback === 'function') {
                    console.log(res,'face+++')
                    const result = JSON.parse(res.desData);
                    callback(result);
                }
            }else{
                if (callback && typeof callback === 'function') {
                    console.log(res,'face error')
                    const result = JSON.parse(res.desData);
                    callback(result);
                }
                console.log(res.message,'2')
                Toast.info(res.message)
            }
        },
    }
};
