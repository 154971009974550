import {request, api} from '../utils/index';

const {
  loan_submit,
  loan_fee,
  init_pay_stage,
  init_pay_all,
  init_Bank_list,
  init_Valid_card,
  init_Card_sms,
  init_Card_bind,
  init_Card_bind_list,
  init_pay_sms,
  pay_submit,
  pay_check_normal,
  pay_check_settle,
  loan_contract

} = api;


/*借款*/
export function Loan(data) {
  return request({
    url: loan_submit,
    method: 'post',
    data,
  })
}


/*借款服务费*/
export function Loan_fee(data) {
  return request({
    url: loan_fee,
    method: 'post',
    data,
  })
}



/*分期还款数据*/
export function Repay_Info_Stage(data) {
  return request({
    url: init_pay_stage,
    method: 'post',
    data,
  })
}

/*全部结清数据*/
export function Repay_Info_All(data) {
  return request({
    url: init_pay_all,
    method: 'post',
    data,
  })
}

/*银行卡列表*/
export function Bank_list(data) {
  return request({
    url: init_Bank_list,
    method: 'post',
    data,
  })
}


/*  是否签约 */
export function Valid_card(data) {
  return request({
    url: init_Valid_card,
    method: 'post',
    data,
  })
}

/* 绑卡短信 */
export function Bind_Capital_Card_sms(data) {
  return request({
    url: init_Card_sms,
    method: 'post',
    data,
  })
}


/* 银行卡签约 */
export function Bind_Card(data) {
  return request({
    url: init_Card_bind,
    method: 'post',
    data,
  })
}

/* 已经签约银行卡列表 */
export function Bind_Card_List(data) {
  return request({
    url: init_Card_bind_list,
    method: 'post',
    data,
  })
}


/*还款短信*/
export function Repay_Send_Sms(data) {
  return request({
    url: init_pay_sms,
    method: 'post',
    data,
  })
}

/*还款提交*/
export function Repay_Submit(data) {
  return request({
    url: pay_submit,
    method: 'post',
    data,
  })
}

/*去结清检查*/
export function Pay_Check_Settle(data) {
  return request({
    url: pay_check_settle,
    method: 'post',
    data,
  })
}

/*去还款检查*/
export function Pay_Check_Normal(data) {
  return request({
    url: pay_check_normal,
    method: 'post',
    data,
  })
}

/*协议*/
export function Loan_Contract(data) {
  return request({
    url: loan_contract,
    method: 'post',
    data,
  })
}
