import ProTypes from "prop-types";
import React from "react";
import APP from "../../../utils/APP";
import { H5Url, money } from "../../../utils";

const MainCard = ({ children, cardIndex, clientNo ,is_login}) => {
  // 按钮跳转逻辑
  const CardJump = (item) => {
    const {button,creditState} = item;
    // 设置标题 是否可点击
    // console.log(clientNo,creditState,99)
    if (button.clickable !== 1) return;
    const url = button.url;
    // creditState 1  去开通 2 
    console.log(is_login,'222')
    if(creditState==1  && is_login==false){
        APP.JUMP_NATIVE(url);
    }else{
        // console.log(url,965)
        APP.JUMP(url);
        
    }
  };

  // 卡片布局
  const RenderMainCard =
    cardIndex &&
    cardIndex.map((item, index) => {
      return (
        <div key={index} className={"main-card-content"}>
          <div className={"card-title"}>
            <div className={"title-borrow"}>
              <div className={"borrow-text"}>您的最高可借额度(元)</div>
              <div className={"borrow-money"}>{item.availableAmt}</div>
            </div>
            <div className={"card-desc"}>
              <span>快速审批</span>
              <span>灵活借款</span>
            </div>
            <div className={"card-desc-text"}>
              {item.creditTip}
            </div>
          </div>
          <div className={"card-btn-footer"}>
            <div
              onClick={() => {
                CardJump(item)
              }}
              className={item.button.clickable==0?'card-btn-dis':'card-btn'}
            >
              {item.button.label}
            </div>
          </div>
        
        </div>
      );
    });

  return <div className={"main-card"}>{RenderMainCard}</div>;
};

MainCard.propTypes = {
  cardIndex: ProTypes.array,
  clientNo: ProTypes.string,
};

export default MainCard;
