export const money = (s, n = 2) => {
  if (!s) s = 0;
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
  let l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
  }
  return t.split("").reverse().join("") + "." + r;
};


export const SearchParams = (location) => {
  return new URLSearchParams(location);
};

export const base64ToFile=(urlData)=>{
  let arr = urlData.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bytes = atob(arr[1]); // 解码base64
  let n = bytes.length;
  let ia = new Uint8Array(n);
  while (n--) {
      ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], `${new Date().getTime()}.jpg`, { type: mime });
}


