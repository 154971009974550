import ProTypes from 'prop-types';
import React from 'react';
import Swiper from 'react-id-swiper';
import APP from "../../../utils/APP";

const Banner = ({children, data, times}) => {


    // swiper banner 参数
    const params = {
        observer: true,
        observeParents: true,
        autoplay: true,
        // pagination: {
        //     el: '.swiper-pagination',
        //     type: 'bullets',
        //     clickable: true
        // },
        spaceBetween: 30,
        delay: times ? times * 1000 : 3000
    };

    // banner 图片调转
    const SwiperHandle = function (item) {
        const {targetUrl, targetType} = item;
        APP.JUMP(targetUrl, targetType);
    }

    // banner 项
    const RenderBannerItem = data && data.map((item, index) => {
        return (
            <div onClick={()=>{SwiperHandle(item)}} key={index} className={'b-slide'}>
                <div className={'b-slide-box'}>
                    <img src={item.imgUrl} alt={'banner'}/>
                </div>
            </div>
        )
    });


    return (
        <div className={'banner'}>
            <Swiper {...params}>
                {RenderBannerItem}
            </Swiper>
        </div>
    )
};

Banner.propTypes = {
    data: ProTypes.array,
    times: ProTypes.string
};

export default Banner;
